/*
 * @Descripttion: 
 * @version: 
 * @Author: Tperam
 * @Date: 2021-04-23 15:50:15
 * @LastEditors: Tperam
 * @LastEditTime: 2021-04-25 17:15:40
 */
import React from 'react'
import { view as MultipleUserBox } from '../auth/mutlipleUser'
import Footer from '../common/footer'

const MultipleUser = (props) => {
  return (
    <div className="box">
        <MultipleUserBox {...props} />
      <Footer />
    </div>
  )
}

export default MultipleUser
