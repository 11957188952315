import React from 'react'
import { view as ForgetPwdBox } from '../auth/forgetPwd/'
import Logo from '../common/logo'
import Footer from '../common/footer'

const ForgetPwd = (props) => {
  return (
    <div className="box">
      {/*<Card className="card">
        <Row gutter={24}>
          <Col xs={{span: 24}} sm={{span: 12}}>
            <ForgetPwdBox {...props} />
          </Col>
          <Col xs={{span: 0}} sm={{span: 12}}>
            <Logo />
          </Col>
        </Row>
      </Card>*/}
        <ForgetPwdBox {...props} />
      <Footer />
    </div>
  )
}

export default ForgetPwd
