import {legacy_createStore as createStore, combineReducers, applyMiddleware, compose} from 'redux'
import { reducer as Auth } from './auth/'
import { reducer as Utils } from './utils/'
import {thunk} from "redux-thunk";

const reducer = combineReducers({
  auth: Auth,
    utils: Utils,
})


const storeEnhancers = compose(
  applyMiddleware(thunk),
  (window && window.devToolsExtension) ? window.devToolsExtension() : (f) => f,
)

export default createStore(reducer, {}, storeEnhancers)
