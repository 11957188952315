import {createMuiTheme} from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';

export default createMuiTheme({
    palette: {
        primary: {//漂亮金
            light: '#ffe080',
            main: '#cfae51',
            dark: '#9b7f22',
            contrastText: '#000',
        },
        secondary: {
            light: '#494949',
            main: '#222',
            dark: '#000',
            contrastText: '#fff',
        }
    },
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiTableCell: { // 组件的名称 ⚛️ / 样式表
             // 规则的名称
            root: {
                    padding: '4px 12px 4px 12px'
            }

        },
        MuiButton: { // 组件的名称 ⚛️ / 样式表
            // 规则的名称
            root: {
                textTransform: "none"
            }

        },
    },
});