import React from 'react'
import { view as SignupBox } from '../auth/signup/'
import Logo from '../common/logo'
import Footer from '../common/footer'

const Signup = (props) => {
  return (
    <div className="box">

        <SignupBox {...props} />
        {/*<Row gutter={24}>
          <Col xs={{span: 24}} sm={{span: 12}}>
            <SignupBox {...props} />
          </Col>
          <Col xs={{span: 0}} sm={{span: 12}}>
            <Logo />
          </Col>
        </Row>*/}
      <Footer />
    </div>
  )
}

export default Signup
