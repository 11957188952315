import React from 'react'
import {Link} from 'react-router-dom'
import ThirdEnter from './thirdEnter'
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import blue from '@material-ui/core/colors/blue';

import {ArrowLeft} from "@material-ui/icons";

const styles = theme => ({
    Return: {
        position: 'absolute',
        top:-40.5,
        left:0
    },
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        position:'relative',
        marginTop: theme.spacing.unit * 16,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 2,
    },
    aLink: {
        color: blue[500],
    },
});

class LoginForm extends React.Component {

    componentDidMount() {
        const form = document.querySelector("form");
        form.onkeydown=(e)=>{
            if(e.key==="Enter"){
                this.handleSubmit(e)
            }
        }
    }

    handleReturn = () => {
        window.location.href = "http://" + this.props.source;
    }
    handleSubmit = (e) => {
        e.preventDefault()
        if (!this.props.username) {
            this.props.messageError('请输入用户名！');
            return;
        }
        if (!this.props.password) {
            this.props.messageError('请输入密码！');
            return;
        }
        this.props.onSubmit();
    };

    render() {
        const {classes} = this.props;
        // console.log(this.props)
        return (
            <div>
                <main className={classes.main}>
                    <CssBaseline/>

                    <Paper className={classes.paper}>

                        {this.props.source
                            ?
                            <Button
                                className={classes.Return}
                                onClick={this.handleReturn}
                            >
                                <ArrowLeft color={"primary"}/>返回</Button>
                            :
                            <></>}
                        {/*<Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>*/}
                        <Typography component="h1" variant="h5">
                            {this.props.isBinding ? '绑定' : this.props.title || "登录"}
                        </Typography>
                        <form onSubmit={this.handleSubmit} className={classes.form}>
                            <FormControl margin="normal" required fullWidth>
                                <InputLabel htmlFor="username">用户名/手机号/邮箱</InputLabel>
                                <Input id="username" name="username" autoComplete="username" autoFocus
                                       value={this.props.username || ""} onChange={this.props.onChange}/>
                            </FormControl>
                            <FormControl margin="normal" required fullWidth>
                                <InputLabel htmlFor="password">密码</InputLabel>
                                <Input name="password" type="password" id="password" autoComplete="current-password"
                                       value={this.props.password || ""}
                                       onChange={this.props.onChange}/>
                            </FormControl>
                            {/*<FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        />*/}
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={this.handleSubmit}
                                className={classes.submit}
                            >
                                登录
                            </Button>
                            <div style={{float: 'right'}}>
                                {this.props.isBinding ? null : (<ThirdEnter source={this.props.source}/>)}
                            </div>
                            <Typography gutterBottom>
                                <Link to={{pathname: '/signup', search: this.props.query}}
                                      className={classes.aLink}>注册</Link>
                            </Typography>
                            <Typography>
                                <Link to={{pathname: '/forget', search: this.props.query}}
                                      className={classes.aLink}>忘记密码?</Link>
                            </Typography>


                        </form>
                    </Paper>
                </main>
                {/*<Form>
                <Form.Item>
                    <h1>{this.props.isBinding ? '绑定' : '登录'}</h1>
                </Form.Item>
                <Form.Item>
                    {this.props.form.getFieldDecorator('username', {
                        rules: [{ required: true, message: '请输入用户名！' }],
                    })(
                        <Input prefix={<Icon type="user" />} placeholder="用户名" />
                    )}
                </Form.Item>
                <Form.Item>
                    {this.props.form.getFieldDecorator('password', {
                        rules: [{ required: true, message: '请输入密码！' }],
                    })(
                        <Input prefix={<Icon type="lock" />} type="password" placeholder="密码" />
                    )}
                </Form.Item>
                <Form.Item>
                    <Button onClick={this.handleSubmit} type="primary" htmlType="submit" loading={this.props.loading} style={{width: "100%"}}>登录</Button>
                </Form.Item>
                <div style={{ float: 'left' }}>
                    <Link to={{ pathname: '/signup', search: this.props.query }}>注册</Link>
                    <p style={ {marginTop: 10 }}><Link to={{ pathname: '/forget', search: this.props.query }}>忘记密码?</Link></p>
                </div>
                {this.props.isBinding ? null : (<ThirdEnter source={this.props.source} />)}
            </Form>*/}
            </div>

        )
    }
}


export default withStyles(styles)(LoginForm)
