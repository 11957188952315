import React from 'react'
import moment from 'moment'
import Typography from '@material-ui/core/Typography';

import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Blue from '@material-ui/core/colors/blue';
import { Link } from 'react-router-dom';

const styles = theme => ({
  root: {
      padding: theme.spacing.unit * 2,
      textAlign: "center"
  },
  verticalMiddle: {
      verticalAlign: 'middle'
  },
});


class Footer extends React.Component {
  getWord = () => {
    const param = window.location.search
    if(param.indexOf('shadowfog') > 0) {
      return 'ShadowFog'
    } else if(param.indexOf('iccrouter') > 0) {
      return 'ICCRouter'
    } else if(param.indexOf('openfogos') > 0) {
      return 'OpenFogOS'
    } else {
      return "Pear"
    }
  }
  render() {
    // const classes = useStyles()
    const {classes} = this.props;
    const word = this.getWord()
    return (
      <footer className={classes.root}>
        <Typography variant="body2" color="textSecondary" align="center" style={{marginTop: '10px'}}>
            Powered by {word} {moment().year()}
        </Typography>
        <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030502002232">
            < img src="/备案图标.png" alt="备案图标" className={classes.verticalMiddle} />
            <Typography align="center" color='textSecondary' variant={"caption"} display="block">粤公网安备 44030502002232号</Typography>
        </a>
        <a target="_blank" href="https://beian.miit.gov.cn/" rel="noopener noreferrer">
            <Typography align="center" color='textSecondary' variant={"caption"} display="block">粤ICP备15114601号</Typography>
        </a>
        
        <a  rel="noopener noreferrer">
            <Typography align="center" color='textSecondary' variant={"caption"} display="block">增值电信业务经营许可证 B1-20180826</Typography>
        </a>
      </footer>

    )
  }
}


export default withStyles(styles)(Footer)