import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom"
import { forgetSendCode, forgetPwdSubmit, renewCaptcha, setSendCodeDisabledTime } from '../actions'
import ForgetPwdForm from './forgetPwdForm'
import {messageError, messageOk, messageWarning} from "../../utils/actions";

class ForgetPwdBox extends Component {
  onSubmit(data) {
    this.props.onForgetPwdSubmit(data, this.props.history)
  }
  onSendCode = (data) => {
    this.props.onSendCode(data)
  }
  render () {
    const formParam = {
      ...this.props,
      messageWarning: this.props.messageWarning,
      onSubmit: data => this.onSubmit(data),
      onSendCode: this.onSendCode,
      loading: this.props.loading,
      query: this.props.location.search,
      
    }
    return (
      <ForgetPwdForm {...formParam}/>
    )
  }
  
  componentDidMount() {
    this.props.onRenewCaptcha()
  }
}

const mapStateToProps = (state) => {
  return {
    loading: (state.auth.forgetPwdStatus === 'loading') ? true : false,
    renewCaptchaLoading: (state.auth.renewCaptchaStatus === 'loading') ? true : false,
    captchaID: state.auth.captchaID,
    captchab64s: state.auth.captchab64s,
    sendCodeDisabledTime: state.auth.sendCodeDisabledTime,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setSendCodeDisabledTime:(data)=>{
      dispatch(setSendCodeDisabledTime(data))
    },
    onRenewCaptcha: () => {
      dispatch(renewCaptcha({
        success: (result) => {
        },
        error: (error) => {
            dispatch(messageError("获取图形验证码失败"));
        }
      }))
    },
    onForgetPwdSubmit: (data, his) => {
      dispatch(forgetPwdSubmit({
        data,
        success: (res) => {
            dispatch(messageOk('修改密码成功！'))
          his.push('./login')
        },
        error: (error) => {
            dispatch(messageError('验证码错误或超时！'));
        }
      }))
    },
    onSendCode: (data) => {
      dispatch(forgetSendCode({
        data,
        success: (res) => {
            dispatch(messageOk('验证码已发送至您的手机'))
        },
        error: (error) => {
            dispatch(messageError(error.msg?error.msg:"发生错误请联系官方人员（微信：Cheedoong"));
            dispatch(renewCaptcha({
              success: (result) => {
              },
              error: (error) => {
                dispatch(messageError("获取图形验证码失败"));
              }
            }))
        }
      }))
    },
      messageOk: (data) => {
          dispatch(messageOk(data));
      },
      messageError: (data) => {
          dispatch(messageError(data));
      },
      messageWarning: (data) => {
          dispatch(messageWarning(data));
      }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ForgetPwdBox))
