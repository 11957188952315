/*
 * @Descripttion: 
 * @version: 
 * @Author: Tperam
 * @Date: 2021-04-12 09:52:30
 * @LastEditors: Tperam
 * @LastEditTime: 2021-11-15 15:40:20
 */
import moment from 'moment'
const Status = {
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

const checkTime = () => (
    ((moment().unix() - localStorage.getItem('account_timestamp')) < 6 * 3600 ) ? true : false
)

export default (state = {result: {}, realnameInfo: {}, isAuthenticated: false, sendCodeDisabledTime: 0}, action) => {
    const setStorage = (result) => {
        localStorage.setItem('account_token', result.token)
        localStorage.setItem('account_userid', result.user_id)
        localStorage.setItem('account_username', result.username)
        localStorage.setItem('account_timestamp', moment().unix())
    }
  switch(action.type) {
    case 'AUTH/LOGIN_STARTED': {
      return {...state, loginStatus: Status.LOADING}
    }
    case 'AUTH/LOGIN_SUCCESS': {
      localStorage.setItem("token", action.result.token);
      return {...state, loginStatus: Status.SUCCESS, result: action.result, isAuthenticated: true}
    }
    case 'AUTH/LOGIN_FAILURE': {
      return {...state, loginStatus: Status.FAILURE}
    }
    
    case 'AUTH/LOGIN/TMP/CODE_STARTED': {
      return {...state, getTmpCodeStatus: Status.LOADING}
    }
    case 'AUTH/LOGIN/TMP/CODE_SUCCESS': {
      localStorage.setItem("code", action.result.code);
      return {...state, getTmpCodeStatus: Status.SUCCESS, tmpCode: action.result["code"]}
    }
    case 'AUTH/LOGIN/TMP/CODE_FAILURE': {
      return {...state, getTmpCodeStatus: Status.FAILURE}
    }

    // 由于可能有多个用户，所以单独处理
    case 'AUTH/WECHAT_PUBLIC/MULTIPLE/USERS/LOGIN_STARTED': {
      return {...state, loginStatus: Status.FAILURE}
    }
    case 'AUTH/WECHAT_PUBLIC/MULTIPLE/USERS/LOGIN_SUCCESS': {
      return {...state, loginStatus: Status.SUCCESS, result: action.result, isAuthenticated: true}
    }
    case 'AUTH/WECHAT_PUBLIC/MULTIPLE/USERS/LOGIN_FAILURE': {
      return {...state, loginStatus: Status.FAILURE}
    }
    
    case 'AUTH/WECHAT_PUBLIC/BIND_STARTED': {
      return {...state, loginStatus: Status.FAILURE}
    }
    case 'AUTH/WECHAT_PUBLIC/BIND_SUCCESS': {
      return {...state, loginStatus: Status.SUCCESS, result: action.result, isAuthenticated: true}
    }
    case 'AUTH/WECHAT_PUBLIC/BIND_FAILURE': {
      return {...state, loginStatus: Status.FAILURE}
    }

    case 'SET/SEND/CODE/DISABLED/TIME': {
      return {...state, sendCodeDisabledTime: action.result}
    }
    case 'AUTH/SEND/CODE_STARTED': {
      return {...state, sendCodeStatus: Status.LOADING}
    }
    case 'AUTH/SEND/CODE_SUCCESS': {
      return {...state, sendCodeStatus: Status.SUCCESS,sendCodeDisabledTime: 120}
    }
    case 'AUTH/SEND/CODE_FAILURE': {
      return {...state, sendCodeStatus: Status.FAILURE}
    }
    case 'AUTH/RENEW_CAPTCHA_STARTED': {
      return {...state, renewCaptchaStatus: Status.LOADING}
    }
    case 'AUTH/RENEW_CAPTCHA_SUCCESS': {
      return {...state, renewCaptchaStatus: Status.SUCCESS, captchaID:action.result.id,captchab64s: action.result.b64s}
    }
    case 'AUTH/RENEW_CAPTCHA_FAILURE': {
      return {...state, renewCaptchaStatus: Status.FAILURE}
    }
    case 'AUTH/SIGNUP_STARTED': {
      return {...state, signupStatus: Status.LOADING}
    }
    case 'AUTH/SIGNUP_SUCCESS': {
      return {...state, signupStatus: Status.SUCCESS, result: action.result}
    }
    case 'AUTH/SIGNUP_FAILURE': {
      return {...state, signupStatus: Status.FAILURE}
    }
    case 'AUTH/REALNAMEINFO_STARTED': {
      return {...state, realnameInfoStatus: Status.LOADING}
    }
    case 'AUTH/REALNAMEINFO_SUCCESS': {
      return {...state, realnameInfoStatus: Status.SUCCESS, realnameInfo: action.result}
    }
    case 'AUTH/REALNAMEINFO_FAILURE': {
      return {...state, realnameInfoStatus: Status.FAILURE}
    }
    case 'AUTH/REALNAME_STARTED': {
      return {...state, realnameStatus: Status.LOADING}
    }
    case 'AUTH/REALNAME_SUCCESS': {
      return {...state, realnameStatus: Status.SUCCESS}
    }
    case 'AUTH/REALNAME_FAILURE': {
      return {...state, realnameStatus: Status.FAILURE}
    }
    case 'FORGET/SUBMIT_STARTED': {
      return {...state, forgetPwdStatus: Status.LOADING}
    }
    case 'FORGET/SUBMIT_SUCCESS': {
      return {...state, forgetPwdStatus: Status.SUCCESS}
    }
    case 'FORGET/SUBMIT_FAILURE': {
      return {...state, forgetPwdStatus: Status.FAILURE}
    }
    case 'AUTH/MAGIC_STARTED': {
        return {...state, magicStatus: Status.LOADING}
    }
    case 'AUTH/MAGIC_SUCCESS': {
        return {...state, magicStatus: Status.SUCCESS, magic: action.result}
    }
    case 'AUTH/MAGIC_FAILURE': {
        return {...state, magicStatus: Status.FAILURE}
    }
    case 'AUTH/LOGIN': {
      localStorage.setItem("token", action.data.token);
      localStorage.setItem("user_id", action.data.user_id);
      let query = encodeURIComponent(Object.entries(action.data).reduce((q, x) => `${q}&${x[0]}=${x[1]}`, '').slice(1));
      localStorage.setItem('redirectQuery', '/login?' + query);
      return {...state, isAuthenticated: true}
    }
    case "GET/PEARBOX/BINDING_STARTED":{
      return {...state,getBindStatus: Status.LOADING}
    }
    case "GET/PEARBOX/BINDING_SUCCESS":{
        return {...state,getBindStatus: Status.SUCCESS, pearboxBindingInfo: action.result}
    }
    case "GET/PEARBOX/BINDING_FAILURE":{
        return {...state,getBindStatus: Status.FAILURE}
    }
    default: {
      return state
    }
  }
}
