import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom"
import { changePwdSubmit } from '../actions'
import ChangePasswordForm from './changePasswordForm'
import {messageError, messageOk, messageWarning} from "../../utils/actions";

class ForgetPwdBox extends Component {
  onSubmit(data) {
    this.props.onSubmit(data, this.props.history)
  }
    getArgs() {
        let args = {},
            query = this.props.location.search,
            search = decodeURIComponent(query.substring(1)),
            reg = /(?:([^&]+)=([^&]+))/g,
            match = reg.exec(search)
        while (match) {
            args[match[1]] = match[2]
            match = reg.exec(search)
        }
        return args
    }
  render () {
      let query = this.args = this.getArgs()
    const formParam = {
        username: query.u,
        messageWarning: this.props.messageWarning,
      onSubmit: data => this.onSubmit(data),
      loading: this.props.loading,
      query: this.props.location.search,
    }
    return (
      <ChangePasswordForm {...formParam}/>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: (state.auth.forgetPwdStatus === 'loading') ? true : false
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (data, his) => {
      dispatch(changePwdSubmit({
        data,
        success: (res) => {
            dispatch(messageOk('修改密码成功！'));
          window.history.go(-1);
        },
        error: (error) => {
            dispatch(messageError('修改密码失败！'));
        }
      }))
    },
      messageOk: (data) => {
          dispatch(messageOk(data));
      },
      messageError: (data) => {
          dispatch(messageError(data));
      },
      messageWarning: (data) => {
          dispatch(messageWarning(data));
      }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ForgetPwdBox))
