/*
 * @Descripttion: 
 * @version: 
 * @Author: Tperam
 * @Date: 2021-04-23 15:53:10
 * @LastEditors: Tperam
 * @LastEditTime: 2022-03-14 15:21:30
 */
import React from 'react'
import { connect } from 'react-redux'
import {wechatPublicLogin} from '../actions'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import blue from "@material-ui/core/colors/blue";
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    card: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    title : {
        marginBottom:theme.spacing.unit*2,
    },
    button: {
        width: '100%',
    },
    aLink: {
        color: blue[500],
    },
});

class MultipleUserBox extends React.Component {
    constructor(props){
        super(props)
        // if (window.location.href.indexOf("https://wechat.") == -1){
        //     // 判断url
        //     window.location.href = "https://account.pear.link/login"
        //     return 
        // }
        this.getArgs = this.getArgs.bind(this)
        const args = this.getArgs()
        // 获取openid，附带参数传给后端
        this.props.OnRequire(args)


    }
    // 获取url参数
    getArgs =()=> {
        let args = {},
        query = this.props.location.search,
        search = decodeURIComponent(query.substring(1)),
        reg = /(?:([^&]+)=([^&]+))/g,
        match = reg.exec(search)
        while (match) {
            args[match[1]] = match[2]
            match = reg.exec(search)
        }
        return args
    }
    login(item){
        localStorage.setItem('token', item.token);
        
        let query = encodeURIComponent(Object.entries(item).reduce((q, x) => `${q}&${x[0]}=${x[1]}`, '').slice(1));

        localStorage.setItem('redirectQuery', '/login?' + query);
        
        const args = this.getArgs()
        let url = "";
        if (args.state.indexOf("https://") != -1){
            url = args.state
            if (url[url.length-1] != '?'){
                url = "https://i.openfogos.com/login?"
            }
        } else {
            // 默认以此网页展示。
            url = "https://i.openfogos.com/login?"
        }
        
        window.location.href = url + query
    }

    render(){
        const {userInfo,classes,logged} = this.props
        return (
            <div>
                <main className={classes.main}>
                    <Card className={classes.card}>
                        <CardContent>
                            
                        <Typography component="h1" variant="h5" className={classes.title}>
                            OpenFogOS
                        </Typography>
                        {logged ? userInfo.map((item,index) => (
                            <Button onClick={()=>{this.login(item)}} key={index} className={classes.button} color="primary" variant="contained" style={{marginTop:10}}>{item.username}</Button>
                        )): "假装有缓冲"} 
                        </CardContent>
                    </Card>
                </main>
            </div>
        )
    }
       
}


const mapStateToProps = (state) => {
    return {
        loading: (state.auth.loginStatus === 'loading') ? true : false,
        logged: (state.auth.loginStatus === 'success') ? true : false,
        userInfo : state.auth.result
    }
}
  
const mapDispatchToProps = (dispatch) => {
    return {
        OnRequire: (data) =>{
            dispatch(wechatPublicLogin(data.openid))
        }
    }
}
  
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(MultipleUserBox))
