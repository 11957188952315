import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import ReactTelInput from 'react-telephone-input'
import 'react-telephone-input/lib/withStyles'
import blue from "@material-ui/core/colors/blue";
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {ArrowLeft} from "@material-ui/icons";


const styles = theme => ({

    Return: {
        position: 'absolute',
        top:-40.5,
        left:0
    },
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        position:'relative',
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 2,
    },
    aLink: {
        color: blue[500],
    },
});

class ForgetPwdForm extends Component {
  constructor() {
    super();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
        username: "",
        password: "",
        confirm_password: "",
        vcode: "",
        captcha_answer: "",
    }
    let int = 0
  }
  handleReturn=()=>{
      window.history.back()
  }
  handleSubmit = () => {
      if(!this.state.password) {
          this.props.messageWarning('请输入密码！');
          return
      }
      if(!this.state.confirm_password) {
          this.props.messageWarning('请输入确认密码！');
          return
      }
      if(!this.state.vcode) {
          this.props.messageWarning('请输入验证码！');
          return
      }

      if(this.state.password != this.state.confirm_password) {
          this.props.messageWarning('两次输入的密码不同！')
          return
      }
      this.props.onSubmit({
          vcode:this.state.vcode,
          username:this.state.username,
          password:this.state.password,
      })
  }
  handleChange = (event)=>{
    this.setState({
        [event.target.name]: event.target.value
    })
  };
  hasErrors() {
    let fieldsError = this.props.form.getFieldsError()
    return Object.keys(fieldsError).some(field => fieldsError[field])
  }
  sendCode = () => {
    const {captchaID} = this.props
    const name = this.state.username
    const captcha_answer = this.state.captcha_answer
    if (!captchaID || !captchaID.length) {
        this.props.messageError('请等待图形验证码')
        return
    }
    if ( !captcha_answer || !captcha_answer.length) {
        this.props.messageError('请输入图形验证码')
        return 
    }
    if(name) {
        const data = {
            username: name,
            captcha_id: captchaID,
            captcha_answer: captcha_answer,
        }
        this.props.onSendCode(data)
        this.int = setInterval(() => {
            this.props.setSendCodeDisabledTime(this.props.sendCodeDisabledTime-1)
        }, 1000)
    } else {
        this.props.messageWarning('请先输入用户名！')
    }
  }
  render() {
      const {classes} = this.props;
    return (
        <div>
            <main className={classes.main}>
                <CssBaseline />
                <Paper className={classes.paper}>
                    <Button
                        className={classes.Return}
                        onClick={this.handleReturn}
                    >
                        <ArrowLeft color={"primary"}/>返回</Button>
                    <Typography component="h1" variant="h5">
                        重置密码
                    </Typography>
                    <form className={classes.form}>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="username">用户名</InputLabel>
                            <Input id="username" name="username" autoComplete="username" autoFocus value={this.state.username || ""} onChange={this.handleChange}/>
                        </FormControl>
                        <FormControl margin="normal" required >
                            <Grid container spacing={1}>
                                <Grid item xs={8}>
                                    <InputLabel htmlFor="captcha">图形验证码</InputLabel>
                                    <Input id="captcha_answer" name="captcha_answer" value={this.state.captcha_answer || ""} onChange={this.handleChange}/>
                                </Grid>
                                <Grid item xs={4} style={{textAlign: "right"}}>
                                    <Button disabled={this.props.renewCaptchaLoading} onClick={() => this.props.onRenewCaptcha()} size="small">
                                        <img src={this.props.captchab64s} height={"40px"} width={"100px"}/>
                                    </Button>
                                </Grid>
                            </Grid>
                        </FormControl>
                        <Grid container spacing={16}>
                                <Grid item xs={7}>
                                    <FormControl margin="normal" required fullWidth>
                                        <InputLabel htmlFor="vcode">验证码</InputLabel>
                                        <Input id="vcode" name="vcode" autoComplete="vcode" autoFocus value={this.state.vcode || ""} onChange={this.handleChange}/>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={5} style={{textAlign: "right", position:"relative"}}>
                                  <span style={{position:"absolute", bottom:"16px", right:"8px", width:"90%"}}>
                                  <Button disabled={this.props.sendCodeDisabledTime > 0} onClick={() => this.sendCode()} color="primary" variant="contained" size="small" fullWidth>{this.props.sendCodeDisabledTime>0?`${this.props.sendCodeDisabledTime}S`:"发送"}</Button>
                                  </span>

                                </Grid>
                            </Grid>


                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="password">密码</InputLabel>
                            <Input name="password" type="password" id="password" autoComplete="current-password" value={this.state.password || ""}
                                   onChange={this.handleChange}/>
                        </FormControl>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="confirm_password">确认密码</InputLabel>
                            <Input name="confirm_password" type="password" id="confirm_password" value={this.state.confirm_password || ""}
                                   onChange={this.handleChange}/>
                        </FormControl>


                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={this.handleSubmit}
                            className={classes.submit}
                        >
                            提交
                        </Button>
                        <Typography>
                            <Link to={{ pathname: '/login', search: this.props.query }} className={classes.aLink}>登录？</Link>
                        </Typography>

                    </form>
                </Paper>
            </main>
            {/*<Form onSubmit={this.handleSubmit}>
                <Form.Item>
                    <h1>重置密码</h1>
                </Form.Item>
                <Form.Item key="username">
                    {this.props.form.getFieldDecorator('username', {
                        rules: [
                            { required: true, message: '请输入用户名！' }
                        ]
                    })(
                        <Input prefix={<Icon type="user" />} placeholder="用户名" />
                    )}
                </Form.Item>
                <Form.Item key="vcode">
                    <Row>
                        <Col span={16}>
                            {this.props.form.getFieldDecorator('vcode', {
                                rules: [
                                    { required: true, message: '请输入验证码！' },
                                ]
                            })(
                                <Input prefix={<Icon type="message" />} placeholder="验证码" />
                            )}
                        </Col>
                        <Col span={6} push={2}>
                            <Button type="primary" disabled={this.state.btnDisabled} onClick={this.sendCode}>{this.state.btnValue}</Button>
                        </Col>
                    </Row>
                </Form.Item>
                <Form.Item key="password">
                    {this.props.form.getFieldDecorator('password', {
                        rules: [
                            { required: true, message: '请输入密码！' },
                            { min: 6 }
                        ]
                    })(
                        <Input prefix={<Icon type="lock" />} type="password" placeholder="新密码" />
                    )}
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={this.props.loading} disabled={this.hasErrors()} style={{width: "100%"}}>提交</Button>
                </Form.Item>
                <Link to={{pathname: '/login', search: this.props.query}}>登录？</Link>
            </Form>*/}
        </div>

    )
  }
}

export default withStyles(styles)(ForgetPwdForm)
