/*
 * @Author: Tperam
 * @Date: 2021-11-15 15:31:15
 * @LastEditTime: 2021-11-15 17:31:37
 * @LastEditors: Tperam
 * @Description: 
 * @FilePath: \account2\src\auth\redirect\container.js
 */
import React,{useState} from 'react'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button'
import {getTmpCode,getBindInfo} from '../actions'
import { useEffect } from 'react';
import { connect } from 'react-redux'
import {withStyles} from '@material-ui/core/styles';
import {messageOk, messageError} from "../../utils/actions";
import blue from "@material-ui/core/colors/blue";

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { Typography } from '@material-ui/core';

const styles = theme => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    card: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    button: {
        margin: theme.spacing.unit * 2,
    },
    aLink: {
        color: blue[500],
    },
    pearboxButton:{
        width:'100px',
        '& span':{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        margin:'5px',
        padding:"10px",
    },
    title: {
        fontWeigh:"700",
        fontSize:"20px",
    },
    
});

function RedirectPage(props){
    useEffect( ()=>{
        props.onRequire()
    }, [])

    const [waitDialogOpen,setwaitDialogOpen] = useState(false)
    const openWaitDialog = ()=> {
        setwaitDialogOpen(true)
    };
    const closeWaitDialog = ()=> {
        setwaitDialogOpen(false)
    };
    // 处理跳转Pearbox部分
    // --------------------------------------------------------------------------------------------
    const {pearboxBindingInfo,tmpCode,classes} = props;
    let pearbox = [];
    console.log(pearboxBindingInfo, pearboxBindingInfo instanceof Array);
    let p = (pearboxBindingInfo || []).sort((a,b)=>{
      return a.level  - b.level
    });
    (p || [] ).map( (v)=>{
        if (pearbox[v.device_id] instanceof  Array){
            pearbox[v.device_id].push(v)
        } else {
            pearbox[v.device_id] = [v]
        }
    })
    
    let pearboxBindingInfoDiv = [];
    for (let tmp in pearbox){
        let url = pearbox[tmp][0].url;
        if (url[url.length-1] !== '/'){
            url += "/"
        }
        pearboxBindingInfoDiv.push(
          <Button onClick={()=>{window.location.href = url + 'index.php/?plugin/oemPear/auth&state=login&code=' + tmpCode}} color="primary" variant="contained" className={`${classes.pearboxButton}`}>{tmp.length>9?tmp.slice(0,9)+"...":tmp}</Button>
        )
    }
    // --------------------------------------------------------------------------------------------

    
    const redirect = (url) => () => {
    window.location.href = url + localStorage.getItem('redirectQuery');
    }

    return (
        <main className={classes.main}>
                <Card className={classes.card}>
                    <CardContent>
                        <Button onClick={redirect("https://i.openfogos.com")} color="primary" variant="contained" style={{marginRight:10}}>OpenFogOS</Button>
                        {props.magic? <a href={"http://" + props.magic[0].Domain} target='_blank'> <Button  color="primary" variant="contained" style={{marginRight:10}}>KM</Button></a>:
                            <Button  color="primary" variant="contained" style={{marginRight:10}} onClick={openWaitDialog} waitDialogOpen={waitDialogOpen} closeWaitDialog={closeWaitDialog}>KM</Button>}
                    </CardContent>

                </Card>

                {pearboxBindingInfoDiv.length > 0?<Card className={classes.card}>
                    <CardContent>
                        <Typography className={classes.title} color="primary" gutterBottom>
                            PearBox 跳转链接
                        </Typography>
                    </CardContent>
                    <CardContent>
                        {pearboxBindingInfoDiv}
                    </CardContent>
                </Card>:null}
                <WaitDialog />
            </main>
    )
}

function WaitDialog(props){
    return (<Dialog open={props.waitDialogOpen} onClose={props.closeWaitDialog}>
        <DialogTitle>内测中</DialogTitle>
        <DialogContent>
            该功能正在内测中，如有兴趣，请联系我们
        </DialogContent>
        <DialogActions>
            <Button onClick={props.closeWaitDialog} color="primary">
                取消
            </Button>
            <Button onClick={props.closeWaitDialog} color="primary" autoFocus>
                确定
            </Button>
        </DialogActions>
    </Dialog>)
}

const mapStateToProps = (state) => {
    return {
      loading: (state.auth.loginStatus === 'loading') ? true : false,
      isAuthenticated: state.auth.isAuthenticated,
      pearboxBindingInfo: state.auth.pearboxBindingInfo || [],
      tmpCode: state.auth.tmpCode || "",
      magic: state.auth.magic,
    }
  }
  
const mapDispatchToProps = (dispatch) => {
    return {
        onRequire:()=>{
            dispatch(getBindInfo())
            dispatch(getTmpCode({
                client_id: "test",
              }, {
                success:(r)=>{
                },
                error:(err)=>{
                  dispatch(messageError("服务器错误"))
                }
            }))
        }
    }
}
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(RedirectPage))