/*
 * @Descripttion: 
 * @version: 
 * @Author: Tperam
 * @Date: 2021-04-25 10:16:54
 * @LastEditors: Tperam
 * @LastEditTime: 2021-09-15 14:37:37
 */
const initEvent = (event) => ({
  success: () => {},
  error: () => {},
  ...event
})

const initAction = (action, data) => ({
  start: () => ({
    type: action + '_STARTED'
  }),
  success: (result) => ({
    type: action + '_SUCCESS',
    result,
    data
  }),
  failure: (error) => ({
    type: action + '_FAILURE',
    error
  })
})

const xhr = ({api, ...param}) => {
  const data = param.data || undefined
  const token = param.token || localStorage.getItem('token')
  const user = param.user_id || localStorage.getItem('user_id')
  const event = initEvent(param.event)
  const action = initAction(param.action, param.data)
  // console.log(data)
  return (dispatch) => {
    dispatch(action.start())
    return fetch(api.url, {
      method: api.method,
      headers: new Headers({
        'X-Pear-Token': token,
        'X-Pear-User': user,
        "Content-Type":"application/json",
      }),
      body: JSON.stringify(data)
    })
    .then((response) => {
      response.json().then((responseJson) => {
        switch (response.status) {
          case 200:
            dispatch(action.success(responseJson))
            event.success(responseJson)
            break
          default:
            dispatch(action.failure(responseJson))
            event.error(responseJson)
            break
        }
      }).catch((error) => {
        dispatch(action.failure(error))
        event.error(error)
      })
    })
    .catch((error) => {
      dispatch(action.failure(error))
      event.error(error)
    })
  }
}

export default xhr
