import React from 'react'
import { connect } from 'react-redux'
import { closeMessageOk, closeMessageError, closeMessageWarning, closeMessageInfo } from './actions'
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import WarningIcon from '@material-ui/icons/Warning';
import classNames from 'classnames';

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

const styles1 = theme => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.dark,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing.unit,
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    margin: {
        margin: theme.spacing.unit,
    },
});

function MySnackbarContent(props) {
    const { classes, className, message, onClose, variant, ...other } = props;
    const Icon = variantIcon[variant];

    return (
        <SnackbarContent
            className={classNames(classes[variant], className)}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
                    {message}
        </span>
            }
            action={[
                <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    className={classes.close}
                    onClick={onClose}
                >
                    <CloseIcon className={classes.icon} />
                </IconButton>,
            ]}
            {...other}
        />
    );
}

MySnackbarContent.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    message: PropTypes.node,
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

const styles = theme => ({
    close: {
        padding: theme.spacing.unit / 2,
    },
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.dark,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing.unit,
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
});

class SnackBars extends React.Component {
    constructor(props, context) {
        super(props, context)
    }

    render () {
        const {classes} = this.props;
        console.log('messageOk')
        return (
            <div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={this.props.messageOk}
                    autoHideDuration={1300}
                    onClose={this.props.closeMessageOk}
                >
                    <SnackbarContent
                        className={classNames(classes.success, classes.margin)}
                        aria-describedby="client-snackbar"
                        message={
                            <span id="client-snackbar" className={classes.message}>
                                <CheckCircleIcon className={classNames(classes.icon, classes.iconVariant)} />
                                {this.props.messageOkStr}
                            </span>
                        }
                        action={[
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                className={classes.close}
                                onClick={this.props.closeMessageOk}
                            >
                                <CloseIcon />
                            </IconButton>,
                        ]}
                    />
                </Snackbar>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={this.props.messageError}
                    autoHideDuration={1300}
                    onClose={this.props.closeMessageError}
                >
                    <SnackbarContent
                        className={classNames(classes.error, classes.margin)}
                        aria-describedby="client-snackbar"
                        message={
                            <span id="client-snackbar" className={classes.message}>
                                <ErrorIcon className={classNames(classes.icon, classes.iconVariant)} />
                                {this.props.messageErrorStr}
                            </span>
                        }
                        action={[
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                className={classes.close}
                                onClick={this.props.closeMessageError}
                            >
                                <CloseIcon />
                            </IconButton>,
                        ]}
                    />
                </Snackbar>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={this.props.messageWarning}
                    autoHideDuration={1300}
                    onClose={this.props.closeMessageWarning}
                >
                    <SnackbarContent
                        className={classNames(classes.warning, classes.margin)}
                        aria-describedby="client-snackbar"
                        message={
                            <span id="client-snackbar" className={classes.message}>
                                <WarningIcon className={classNames(classes.icon, classes.iconVariant)} />
                                {this.props.messageWarningStr}
                            </span>
                        }
                        action={[
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                className={classes.close}
                                onClick={this.props.closeMessageWarning}
                            >
                                <CloseIcon />
                            </IconButton>,
                        ]}
                    />
                </Snackbar>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={this.props.messageInfo}
                    autoHideDuration={1300}
                    onClose={this.props.closeMessageInfo}
                >
                    <SnackbarContent
                        className={classNames(classes.info, classes.margin)}
                        aria-describedby="client-snackbar"
                        message={
                            <span id="client-snackbar" className={classes.message}>
                                <InfoIcon className={classNames(classes.icon, classes.iconVariant)} />
                                {this.props.messageInfoStr}
                            </span>
                        }
                        action={[
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                className={classes.close}
                                onClick={this.props.closeMessageInfo}
                            >
                                <CloseIcon />
                            </IconButton>,
                        ]}
                    />
                </Snackbar>

            </div>

        )
    }
}

SnackBars.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        messageOk: state.utils.messageOk,
        messageOkStr: state.utils.messageOkStr,
        messageError: state.utils.messageError,
        messageErrorStr: state.utils.messageErrorStr,
        messageWarning: state.utils.messageWarning,
        messageWarningStr: state.utils.messageWarningStr,
        messageInfo: state.utils.messageInfo,
        messageInfoStr: state.utils.messageInfoStr,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        closeMessageOk: () => {
            dispatch(closeMessageOk())
        },
        closeMessageError: () => {
            dispatch(closeMessageError())
        },
        closeMessageWarning: () => {
            dispatch(closeMessageWarning())
        },
        closeMessageInfo: () => {
            dispatch(closeMessageInfo())
        }
    }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(SnackBars))