import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import ReactTelInput from 'react-telephone-input'
import 'react-telephone-input/lib/withStyles'
import blue from "@material-ui/core/colors/blue";
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';


const styles = theme => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 2,
    },
    aLink: {
        color: blue[500],
    },
});

class ChangePasswordForm extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
      this.newPasswordOnBlur = this.newPasswordOnBlur.bind(this);
      this.confirmPasswordOnBlur = this.confirmPasswordOnBlur.bind(this);
    this.state = {
      btnValue: '发送',
      btnDisabled: false,
        username: props.username||"",
        old_password: "",
        new_password: "",
        confirm_password: "",
        newPasswordError: false,
        confirmPasswordError: false,
    };
  }
  handleSubmit = () => {
      if(!this.state.old_password) {
          this.props.messageWarning('请输入原密码！');
          return
      }
      if(!this.state.new_password) {
          this.props.messageWarning('请输入新密码！');
          return
      }
      if(!this.state.confirm_password) {
          this.props.messageWarning('请输入确认密码！');
          return
      }
      if(this.state.new_password != this.state.confirm_password) {
          this.props.messageWarning('两次输入的密码不同！')
          return
      }
      this.props.onSubmit({
          username:this.state.username,
          old_password:this.state.old_password,
          new_password:this.state.new_password,

      })
  }
  handleChange = (event)=>{
    this.setState({
        [event.target.name]: event.target.value
    })
  };
  newPasswordOnBlur = ()=>{
      this.setState({
          newPasswordError: this.state.new_password.length < 6,
          confirmPasswordError: this.state.new_password != this.state.confirm_password && this.state.confirm_password.length > 0
      })
  };
    confirmPasswordOnBlur = ()=>{
        this.setState({
            confirmPasswordError: this.state.new_password != this.state.confirm_password
        })
    };
  render() {
      const {classes} = this.props;
      const submitDisabled = (!this.state.username)||(!this.state.old_password)||(!this.state.new_password)||(this.state.new_password != this.state.confirm_password);
    return (
        <div>
            <main className={classes.main}>
                <CssBaseline />
                <Paper className={classes.paper}>
                    <Typography component="h1" variant="h5">
                        修改密码
                    </Typography>
                    <form className={classes.form}>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="username">用户名</InputLabel>
                            <Input id="username" name="username" autoComplete="username" autoFocus value={this.state.username || ""} onChange={this.handleChange}/>
                        </FormControl>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="old_password">原密码</InputLabel>
                            <Input name="old_password" type="password" id="old_password" autoComplete="current-password" value={this.state.old_password || ""}
                                   onChange={this.handleChange}/>
                        </FormControl>
                        <FormControl margin="normal" required fullWidth error={this.state.newPasswordError && this.state.new_password.length < 6}>
                            <InputLabel htmlFor="new_password">新密码(6-20位)</InputLabel>
                            <Input name="new_password" type="password" id="new_password" value={this.state.new_password || ""}
                                   onChange={this.handleChange} onBlur={this.newPasswordOnBlur}/>
                        </FormControl>
                        <FormControl margin="normal" required fullWidth error={this.state.confirmPasswordError && this.state.new_password != this.state.confirm_password}>
                            <InputLabel htmlFor="confirm_password">确认密码</InputLabel>
                            <Input name="confirm_password" type="password" id="confirm_password" value={this.state.confirm_password || ""}
                                   onChange={this.handleChange} onBlur={this.confirmPasswordOnBlur}/>
                        </FormControl>


                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={this.handleSubmit}
                            className={classes.submit}
                            disabled={submitDisabled}
                        >
                            提交
                        </Button>

                    </form>
                </Paper>
            </main>
        </div>

    )
  }
}

export default withStyles(styles)(ChangePasswordForm)
