import React from 'react'
import { connect } from 'react-redux'
import ERROR from '../../Error'
import { login, loginThird, getMagicBoxURL, loginQuery, bindWechatPublic, getTmpCode } from '../actions'
import LoginForm from './loginForm'
import RedirectPage from '../redirect'
import {messageOk, messageError} from "../../utils/actions";
import blue from "@material-ui/core/colors/blue";
import {withStyles} from '@material-ui/core/styles';

const styles = theme => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    card: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    button: {
        margin: theme.spacing.unit * 2,
    },
    aLink: {
        color: blue[500],
    },
});

class LoginBox extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.onFormChange = this.onFormChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      formData: {},
      queryData: {},
      title: "登录"
    }
    // 判断是否是从微信公众号进来的

    if (window.location.href.indexOf("https://wechat.") !== -1) {
      if (window.location.href.indexOf("bind?") === -1){
        // 从微信公众号来的
        // 向微信官方请求code
        var param = {
          appid:"wx0253252851e1874c",
          redirect_uri:encodeURIComponent("https://wechat.webrtc.win/wechat_public/login"),
          response_type:"code",
          scope:"snsapi_base",
          state:"https://i.openfogos.com/login?" // 默认登陆页面，后续可改动
        }
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${param.appid}&redirect_uri=${param.redirect_uri}&response_type=${param.response_type}&scope=${param.scope}&state=${param.state}#wechat_redirect`

      }
    }

  }
  onFormChange(event) {
    this.setState({

      formData: {
        ...this.state.formData,
        [event.target.name]: event.target.value
      }
    })
  }

  onSubmit() {
    this.props.onSubmit(this.state.formData, this.state.queryData)
  }
  getArgs() {
    let args = {},
        query = this.props.location.search,
        search = decodeURIComponent(query.substring(1)),
        reg = /(?:([^&]+)=([^&]+))/g,
        match = reg.exec(search)
    while (match) {
      args[match[1]] = match[2]
      match = reg.exec(search)
    }
    return args
  }

  redirect = (url) => () => {
      window.location.href = url + localStorage.getItem('redirectQuery');
  }
  render () {
    const { classes } = this.props;
    const formParam = {
      onSubmit: this.onSubmit,
      onChange: fields => this.onFormChange(fields),
      loading: this.props.loading,
      query: this.props.location.search,
        messageError: this.props.messageError,
        title: this.state.title,
      ...this.state.formData,
      ...this.state.queryData,
    }


    // 第三方登录，为了兼容微信
    if (this.state.queryData.redirect_url && this.state.queryData.client_id && window.location.href.indexOf("https://third.pear.link")!== -1  ){
      let info = window.btoa(`{"redirect_url":"${this.state.queryData.redirect_url}","client_id":"${this.state.queryData.client_id}","state":"${this.state.queryData.state || ""}"}`)
      
      info = info[info.length-1] === "="? info.substr(0,info.length-1):info
      info = info[info.length-1] === "="? info.substr(0,info.length-1):info
      
      formParam.source=`https://third.pear.link/login?info=${info}`
    }
    // && window.location.href.indexOf("https//third.pear") >= 0 
    // 当跳转到当前页面，同时已经登录时，尝试获取判断，是否是登录第三方网站
    if(this.props.isAuthenticated && this.state.queryData.info && window.location.href.indexOf("https://third.pear.link") !== -1 ) {
      let info = JSON.parse(window.atob(this.state.queryData.info))
      let data = {
        client_id: info.client_id,
      }
      // 是则去获取code，等待跳转。
      
      this.props.getTmpCode(data, {
        success:(r)=>{
          window.location.href = `${info.redirect_url}?code=${r.code}&state=${info.state}`
        },
        error:(err)=>{
          this.props.messageError("服务器错误")
        }
      })
    }

    return <div>
        {!this.props.isAuthenticated? <LoginForm {...formParam}/>:
          <RedirectPage/>
          }
    </div>


  }
  componentDidMount() {
    let args = this.getArgs();
    
    this.setState({
      formData: {
        username: args.username
      },
      queryData: {
        isBinding: !!(args.state && args.openid && args.logintype),
        // source: (args.state === 'public') ? 'nms' : (args.state || args.source || 'nms'),
        source: (args.state === 'public') ? '' : (args.state || args.source || ''),
        openid: args.openid,
        logintype: args.logintype,
        redirect_url: args.redirect_url,
        client_id: args.client_id,
        state: args.state,
        info: args.info,
      },
        title: args.title || "登录",
    })
      if (args.token && args.user_id && args.username) {
          // 发送登录请求
          this.props.onRequire({
              token: args.token,
              user_id: args.user_id,
              username: args.username
          })
      }
  }
  componentDidUpdate() {
      if(!this.state.queryData.source){
          this.props.getMagic();
      }
  }
}


const mapStateToProps = (state) => {
  return {
    loading: (state.auth.loginStatus === 'loading'),
      // logged: (state.auth.loginStatus === 'success') ? true : false,
    isAuthenticated: state.auth.isAuthenticated,
    magic: state.auth.magic,
    pearboxBindingInfo: state.auth.pearboxBindingInfo,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (data, args) => {
        /*let temp = {}
        for (let k in data) {
          temp[k] = data[k].value
        }
        temp.openid = args.openid
        temp.logintype = args.logintype*/
        console.log('args.source-------------------------------------------------------', args.source);
        console.log('args.source.indexOf("http")---------------------------------------', args.source.indexOf("http"));
        const param = {
            data: {
                ...data,
                openid: args.openid,
                logintype: args.logintype
            },
            success: (result) => {
                localStorage.setItem('token', result.token);
                localStorage.setItem('user_id', result.user_id);
                let query = encodeURIComponent(Object.entries(result).reduce((q, x) => `${q}&${x[0]}=${x[1]}`, '').slice(1));
                localStorage.setItem('redirectQuery', '/login?' + query);

                // 如果url参数中附带该内容。则认为是第三方客户端登录。
                if (args.redirect_url && args.client_id && window.location.href.indexOf("https://third.pear.link") !== -1) {

                    // 则发送请求，请求临时code
                    dispatch(getTmpCode({
                        client_id: args.client_id,
                    }, {
                        success: (r) => {
                            window.location.href = `${args.redirect_url}?code=${r.code}&state=${args.state || ""}`
                        },
                        error: (err) => {
                            dispatch(messageError("服务器错误"))
                        }
                    }))
                    return
                }


                if (!args.source) {
                    dispatch(getMagicBoxURL({
                        /*error: (error) => {
                            dispatch(messageError(ERROR[error.errorcode]))
                        }*/
                    }));
                    dispatch(messageOk('登录成功，请选择要登入的系统'));
                    return;
                }

                let redirectURL = ''
                if (args.source.indexOf("/login?") !== -1) {
                    redirectURL = args.source;
                    window.location.href = redirectURL + query;
                    return
                } else if (args.source.indexOf("http") !== -1) {
                    redirectURL = args.source;
                    window.location.href = redirectURL + '/login?' + query;
                    return
                } else if (args.source === 'prctoken') {
                    redirectURL = 'http://' + args.source + '.com'
                } else if (args.source === 'value') {
                    redirectURL = 'https://' + args.source + '.prctoken.com/login'
                } else if (args.source === 'kuiconect') {
                    redirectURL = 'http://kuiconnenct.webrtc.win/login'
                } else if (args.source.indexOf(".") !== -1) {
                    redirectURL = 'https://' + args.source + '/login'
                } else {
                    redirectURL = 'https://' + args.source + '.webrtc.win/login'
                }
                if (args.source === 'kuiconnect') {
                    query = 'ip%3D' + document.referrer + "%26" + query;
                }
                window.location.href = redirectURL + '?' + query
            },
            error: (error) => {
                dispatch(messageError(ERROR[error.errorcode]))
            }
        };
        if (args.isBinding) {
          // 判断是否是公众号
          if (data.logintype ==="wechatpublic"){
            dispatch(bindWechatPublic(param))
          } else {
            // 其他
            dispatch(loginThird(param))
          }
        } else {
          if(args.source === 'value') {
            if(param.data.username === 'nmsadmin' || param.data.username === 'blockchain') {
              dispatch(login(param))
            } else {
                this.props.messageError('对不起，你没有权限进入！')
            }
          } else {
            dispatch(login(param))          
          }
        }
    },
    getMagic: () => {
        dispatch(getMagicBoxURL({
            /*error: (error) => {
                dispatch(messageError(ERROR[error.errorcode]))
            }*/
        }));
    },
    messageOk: (data) => {
      dispatch(messageOk(data));
    },
    messageError: (data) => {
        dispatch(messageError(data));
    },
    getTmpCode:(data,event) =>{
      dispatch(getTmpCode(data,event))
    },
    onRequire: (data) => {
        dispatch(loginQuery(data))
    }
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(LoginBox))
