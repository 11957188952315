/*
 * @Author: Tperam
 * @Date: 2021-11-15 15:31:19
 * @LastEditTime: 2021-11-15 15:43:44
 * @LastEditors: Tperam
 * @Description: 
 * @FilePath: \account2\src\auth\redirect\index.js
 */
import view from './container.js'


export default view
