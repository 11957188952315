import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import { Provider } from 'react-redux'
import store from './Store.js'
import Routes from './Route.js'
import Theme from './themes/mainTheme';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';

import * as serviceWorker from './serviceWorker';
import SnackBars from "./utils/snackbars";

ReactDOM.render(
    <MuiThemeProvider theme={Theme}>
        <Provider store={store}>
            <SnackBars/>
            <Routes />
        </Provider>
    </MuiThemeProvider>,
    document.getElementById('root')
)

serviceWorker.unregister();
