import moment from 'moment'

const Status = {
    LOADING: 'loading',
    SUCCESS: 'success',
    FAILURE: 'failure'
}

const checkTime = () => (
  ((moment().unix() - localStorage.getItem('openfogos_timestamp')) < 6 * 3600 ) ? true : false
)

export default (state = {isAuthenticated: checkTime()}, action) => {
  switch(action.type) {

      case 'UTILS/MESSAGE/OK': {
          return {...state, messageOk: true, messageOkStr: action.dataStr}
      }
      case 'UTILS/MESSAGE/ERROR': {
          return {...state, messageError: true, messageErrorStr: action.dataStr}
      }
      case 'UTILS/MESSAGE/WARNING': {
          return {...state, messageWarning: true, messageWarningStr: action.dataStr}
      }
      case 'UTILS/MESSAGE/INFO': {
          return {...state, messageInfo: true, messageInfoStr: action.dataStr}
      }
      case 'UTILS/MESSAGE/OK/CLOSE': {
          return {...state, messageOk: false, messageOkStr: ""}
      }
      case 'UTILS/MESSAGE/ERROR/CLOSE': {
          return {...state, messageError: false, messageErrorStr: ""}
      }
      case 'UTILS/MESSAGE/WARNING/CLOSE': {
          return {...state, messageWarning: false, messageWarningStr: ""}
      }
      case 'UTILS/MESSAGE/INFO/CLOSE': {
          return {...state, messageInfo: false, messageInfoStr: ""}
      }
      case 'UTILS/REWARD_STATISTICS_STARTED': {
          return {...state, rewardStatisticsStatus: Status.LOADING}
      }
      case 'UTILS/REWARD_STATISTICS_SUCCESS': {
          let result = action.result
          return {...state, rewardStatisticsStatus: Status.SUCCESS, rewardStatistics: result}
      }
      case 'UTILS/REWARD_STATISTICS_FAILURE': {
          return {...state, rewardStatisticsStatus: Status.FAILURE}
      }
      case 'UTILS/TOP5_STARTED': {
          return {...state, top5Status: Status.LOADING}
      }
      case 'UTILS/TOP5_SUCCESS': {
          return {...state, top5Status: Status.SUCCESS, top5: action.result}
      }
      case 'UTILS/TOP5_FAILURE': {
          return {...state, top5Status: Status.FAILURE}
      }
    default: {
      return state
    }
  }
}
